@use '../../../styles/constants/colors' as color;

.button {
  width: 482px;
  height: 52px;
  padding: 9px;
  margin-top: 50px;
  font-size: 30px;
  line-height: 34px;
  color: color.$WHITE;
  background-color: #00e6ac;
  border-radius: 9px;
}
