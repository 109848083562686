// Grey Scale
$WHITE: #ffffff;
$BLACK: #000000;
$GREY_PRIMARY: #797979;
$GREY_SECONDARY: #808080;
$GREY_TERTIARY: #757575;

$GREEN_PRIMARY: #229e78;
$GREEN_SECONDARY: #255647;
$GREEN_TERTIARY: #87ae75;
$GREEN_QUATERNARY: #14331a;

$RED_PRIMARY: #ff0000;

$BEIGE_PRIMARY: #feffc7;
