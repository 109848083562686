@use '../../styles/constants/colors' as color;

.wrapper {
  display: flex;
  width: 100%;
  height: 745px;

  .plantImg {
    width: 600px;
    height: 700px;
    background-color: color.$GREY_SECONDARY;
  }

  .contentsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 200px;

    .plantName {
      display: flex;
      margin-top: 130px;
      margin-left: 20%;
      text-align: center;

      .plantNameKor {
        font-size: 70px;
        font-weight: 800;
        color: color.$GREEN_PRIMARY;
      }

      .plantNameEng {
        margin-left: 10px;
        font-size: 30px;
        font-weight: 800;
        color: color.$GREEN_PRIMARY;
      }
    }

    .plantInfo {
      display: flex;
      flex-direction: column;
      margin-top: 80px;
      margin-left: 100px;

      .black {
        position: absolute;
        margin-bottom: 45px;
        margin-left: 220px;
        font-size: 35px;
        font-weight: 800;
        color: color.$GREY_PRIMARY;
      }

      .gray {
        margin-bottom: 45px;
        margin-left: 500px;
        font-size: 35px;
        font-weight: 800;
        color: color.$BLACK;
      }
    }
  }
}

.even {
  flex-direction: row-reverse;
}
