.myPlantPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;

  .wrraper {
    width: 1000px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 20px;
    padding: 30px;

    .top {
      display: flex;
      flex-direction: column;
      margin: 30px;
      .title {
        font-size: 40px;
        font-weight: 600;
      }
    }

    .contents {
      display: flex;
      flex-direction: column;
      margin-left: 100px;

      .plantImg {
        margin-left: 400px;
        width: 50px;
        height: 50px;
        .pepe {
          object-position: center center;
          object-fit: cover;
          width: 300px;
        }
      }
      .plantInfo {
        display: flex;
        .plantName {
          color: #45b39d;
          font-weight: 600;
          font-size: 40px;
          margin-bottom: 20px;
        }
        .plantKind {
          color: #2c3e50;
          font-size: 20px;
          margin-left: 20px;
          font-weight: 600;
          margin-top: 10px;
        }
      }

      .water {
        .waterDay {
          font-size: 20px;
          font-weight: 600;
          color: #2c3e50;
          margin-bottom: 10px;
        }

        .lastWaterDay {
          margin-left: 20px;
          font-size: 23px;
          font-weight: 600;
          margin-bottom: 80px;
        }

        .nextWaterDay {
          margin-left: 20px;
          font-size: 23px;
          font-weight: 600;
          margin-bottom: 25px;
        }
      }

      .memo {
        .memoTitle {
          margin-top: 50px;
          font-size: 20px;
          font-weight: 600;
          color: #2c3e50;
          margin-bottom: 10px;
        }
        .memoInput {
          margin-left: 20px;
          width: 800px;
          height: 500px;
          border-radius: 20px;
          background-color: #e8f8f5;
        }
      }
    }
  }
}
