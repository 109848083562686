.boardPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .topButton {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 60px;
    margin-bottom: 80px;

    .logoImg {
      width: 220px;
    }

    .menuButton {
      font-size: 30px;
      font-weight: 700;
    }

    .menuButton :hover {
      color: #229e78;
    }

    .logOutButton {
      width: 141px;
      height: 55px;
      font-size: 20px;
      font-weight: 800;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 25%);
    }
  }

  .search {
    display: flex;
    flex-direction: column;
  }
}
