@use '../../styles/constants/colors' as color;

.container {
  width: 2500px;
  height: 650px;
}

/*
.carousel {
  width: 100%;
  height: 100%;
  color: color.$WHITE;
  text-align: center;
  background-color: color.$BLACK;
  border: 1px solid color.$WHITE;
}
*/

.slides {
  width: 1500px;
  height: 600px;
  background-color: white;
  background-color: rgba(255, 255, 255, 30%);
  border-radius: 20px;
}

.slider {
  overflow: visible;
}
