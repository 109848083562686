@use '../../styles/constants/colors' as color;

.wrapper {
  display: flex;
  width: 100%;

  .infoWrapper {
    width: 100%;
    height: 100%;

    .plnatNameBox {
      display: flex;
      margin-top: 55px;
      margin-left: 120px;

      .plantName {
        font-size: 150px;
        font-weight: 800;
        color: color.$WHITE;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 25%);
      }

      .plantNickname {
        margin-top: 65px;
        margin-left: 50px;
        font-size: 60px;
        font-weight: 800;
        color: black;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 50%);
      }

      .plantMemoBox {
        align-items: center;
        justify-content: center;
        margin-top: 55px;
        margin-left: 150px;

        .memoTitle {
          margin-bottom: 15px;
          font-size: 25px;
          font-weight: 700;
          color: #154001;
        }

        .memo {
          font-size: 30px;
          font-weight: 600;
        }
      }
    }

    .plantDate {
      margin-left: 650px;
      font-size: 30px;
      font-weight: 600;
      color: color.$GREEN_QUATERNARY;
    }

    .waterComponent {
      margin-left: 104px;

      .plantWater {
        margin-left: 175px;
        font-size: 50px;
        font-weight: 600;
        color: color.$WHITE;

        .waterDrop {
          margin-right: 20px;
        }
      }

      h5 {
        margin-top: 19px;
        margin-top: -100px;
        margin-left: 175px;
        font-size: 30px;
        font-weight: 600;
        color: color.$WHITE;
      }
    }

    .moreInfoButton {
      margin-top: 120px;
      margin-left: 20%;
      font-size: 30px;
      font-weight: 700;
    }
  }

  .imgWrapper {
    width: 70%;
    height: 100%;

    .plantImg {
      display: block;
      width: 350px;
      margin: 0 auto;
    }
  }
}
