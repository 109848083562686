@use '../../styles/constants/colors' as color;

.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .headBlock {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 500px;
    text-align: right;

    .buttonBlock {
      margin-bottom: 400px;
      margin-left: 20px;
    }

    .titleBlock {
      .title {
        margin-bottom: 100px;
        font-size: 140px;
        font-weight: 800;
        line-height: 100px;

        &--small {
          margin-right: 60%;
          font-size: 80px;
          line-height: 80px;
          color: color.$GREY_TERTIARY;
        }
      }
    }

    .imgWrapper {
      z-index: -100;

      .plant1Img {
        width: 500px;
        height: 100%;
        text-align: right;
        object-position: bottom;
        object-fit: cover;
      }
    }

    .buttonContainer {
      position: absolute;
      left: 50%;
      display: flex;
      gap: 300px;
      transform: translate(-50%, 180px);

      .menu {
        width: 200px;
        height: 130px;
        font-size: 30px;
        font-weight: 700;
        background-color: color.$WHITE;
        border-radius: 20px;

        &:hover {
          color: #229e78;
        }
      }
    }
  }

  .plantBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 650px;
    margin-top: -65px;
    background: linear-gradient(101.66deg, color.$GREEN_SECONDARY 0%, color.$GREEN_TERTIARY 46.17%, color.$BEIGE_PRIMARY 99.03%);

    .carousel {
      margin-top: 20px;

      .fallback {
        font-size: 32px;
      }
    }
  }

  .plantRank {
    width: 100%;
  }
}
