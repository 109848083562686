.loginPage {
  display: flex;
  width: 100%;
  height: 100%;

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;
    text-align: center;
    background-color: #ffffff;

    h1 {
      margin-top: 300px;
      margin-bottom: 80px;
      font-size: 80px;
      font-weight: 600;
      line-height: 80px;
    }

    .loginForm {
      display: flex;
      flex-direction: column;
      align-items: center;

      .textInput {
        width: 550px;
        padding: 9px;
        margin-bottom: 40px;
        font-size: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 9px;
      }

      p {
        align-self: flex-start;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        text-align: left;
      }
    }

    .signIn {
      display: flex;
      margin-top: 30px;

      h2 {
        margin-right: 30px;
        font-size: 20px;
        font-weight: 500;
        color: black;
        text-align: left;
      }

      .signinBtn {
        font-size: 20px;
        font-weight: 500;
        color: #006bb3;
      }
    }
  }

  .blank {
    width: 50%;
    height: 100vh;

    .backImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
