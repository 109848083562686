@use '../../styles/constants/colors' as color;

.registerPlantPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: color.$WHITE;
  background-image: url(../../assets/pngs/cool-background.png);
  background-repeat: no-repeat;
  background-size: cover;

  .title {
    margin-bottom: 40px;
    font-size: 48px;
    font-weight: 700;
  }

  .description {
    margin-bottom: 20px;
    font-size: 32px;
    font-weight: 500;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    justify-content: center;
    width: 600px;
    height: 800px;
    border: 3px solid white;
    border-radius: 8px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 10%);

    .input {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 500px;
      height: 100px;

      & p {
        align-self: flex-start;
        margin-bottom: 4px;
        font-size: 20px;
      }

      & input {
        width: 100%;
        height: 60px;
        padding: 0 8px;
        font-size: 30px;
        border: 3px solid white;
        border-radius: 8px;
      }

      .calendar {
        color: color.$WHITE;
      }
    }

    & button {
      width: 500px;
      height: 60px;
      margin-top: 70px;
      font-size: 24px;
      color: color.$WHITE;
      background-color: rgb(111, 185, 76);
      border-radius: 8px;
    }
  }
}
