@use '../../../styles/constants/colors' as color;

.button {
  width: 141px;
  height: 55px;
  font-size: 20px;
  font-weight: 800;
  background: color.$WHITE;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 25%);
}
