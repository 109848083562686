.listItem {
  width: 1400px;
  border: 1px solid #e9e9e9;
  border-radius: 20px;

  .title {
    display: flex;

    .userPic {
      width: 76px;
      height: 76px;
      margin: 50px;
      background-color: antiquewhite;
      border-radius: 10px;
    }

    h1 {
      margin-top: 60px;
      margin-bottom: 5px;
      font-size: 25px;
      font-weight: 700;
    }

    h2 {
      font-size: 20px;
      font-weight: 500;
    }
  }

  .post {
    width: 1000px;
    margin-left: 50px;
    font-size: 17px;
    line-height: 23px;
  }

  .comment {
    align-items: center;
    width: 150px;
    height: 37px;
    margin: 50px;
    font-size: 20px;
    line-height: 37px;
    color: #2b2f87;
    text-align: center;
    background-color: #d9f2eb;
    border-radius: 10px;
  }
}
